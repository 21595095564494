let key = {};
let IPFS_IMG = " https://naifty.infura-ipfs.io/ipfs/";
var networkVersion = "";
let Back_Url = "";
var FRONT_URL = "";
var ImG = "";
var EnvName = "stage";


var Front_market_Url = ''

if (EnvName === "local") {
  Back_Url = "http://localhost:3331/v1/admin";
  Front_market_Url = "http://localhost:3000/naiftyadmin";
  var image_url = "hhttp://localhost:3331/token";
  ImG = "http://localhost:3331";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var BNBCHAIN = 97
  var chainlist = [97,43113]
  var versions =["0x61","0xa869"]
  var BNBversion = "0x61"
  var ETHTRADE = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var Back_Url_Token = "http://localhost:3331/v1";
}

if (EnvName === "stage") {
  Back_Url = "https://stagingbackend.savagetale.com/v1/admin";
  Front_market_Url = "http://nftdemo.bimaticz.com/naifty";
  var image_url = "https://stagingbackend.savagetale.com/token";
  ImG = "https://stagingbackend.savagetale.com";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var BNBCHAIN = 97
  var chainlist = [97,43113]
  var versions =["0x61","0xa869"]
  var BNBversion = "0x61"
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var Back_Url_Token = "https://stagingbackend.savagetale.com/v1";
}

if (EnvName === "demo") {
  Back_Url = "http://192.53.121.26:7018/v1/admin";
  Front_market_Url = "http://nftdemo.bimaticz.com/naifty";
  var image_url = "http://192.53.121.26:2525/token";
  ImG = "http://192.53.121.26:7018";
  var tradeAddress = "0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d";
  var singleAddress = "0x99d344133e46720e286f9c5527d2cc2e304953d8";
  var multipeAddress = "0x0b6369b506e811df30ab7c0ce0ae00b8690b76be";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var ETHCHAIN = 43113
  var BNBCHAIN = 97
  var chainlist = [97,43113]
  var versions =["0x61","0xa869"]
  var BNBversion = "0x61"
  var ETHversion = "0xa869"
  var ETHTRADE = '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
  var BNBTRADE = "0x419Ee320F99287A93B4362C4A45679E30736d05a".toLowerCase()
  var ETHprovider = "https://api.avax-test.network/ext/bc/C/rpc";
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var Back_Url_Token = "http://192.53.121.26:7018/v1";
}

if (EnvName === "production") {
  Back_Url = "https://backend.savagetale.com/v1/admin";
  Front_market_Url = "http://nftdemo.bimaticz.com/naifty";
  var image_url = "https://backend.savagetale.com/token";
  ImG = "https://backend.savagetale.com";
  var networkVersion = "0xa869"
  var chainId = "0xa869";
  var BNBCHAIN = 97
  var chainlist = [97,43113]
  var versions =["0x61","0xa869"]
  var BNBversion = "0x61"
  var BNBprovider = "https://endpoints.omniatech.io/v1/bsc/testnet/public";
  var Back_Url_Token = "http://192.53.121.26:7018/v1";
}

key = {
  AdminAPI: `${Back_Url}`,
  Back_Url: `${Back_Url_Token}`,
  chainId: chainId,
  BNBProvider: BNBprovider,
  BNBCHAIN : BNBCHAIN,
  chainlist : chainlist,
  bnbver : BNBversion,
  versionlist :versions ,
  ENVname : EnvName,
  ImG: ImG,
  IPFS_IMG: IPFS_IMG,
  ETHTRADE : ETHTRADE,
  AdminAddress: "0x8f4ad1aE5AB57b2DA6DCd0Be762048bB4692C0Ce",
  noimg: require("../assets/images/No_image.webp"),
  FRONT_URL: FRONT_URL,
  Front_market_Url:Front_market_Url,
  networkVersion:networkVersion,
  SECRET_KEY : 'S@v@g3Cr0wn',
  COINNAME : "BNB"
};

export default key;
